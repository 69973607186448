/**
 * configuration applied when BROCOLI_API_MODE = production
 */
module.exports = {
  authOAuthProviders: [
    [
      'WSSOKeycloak',
      {
        realm: 'RTE',
        clientId: 'frequence-maintenance',
        buttonText: 'Identification RTE',
      },
    ],
  ],
}
