export default function (context, inject) {
  const config = {
  "metadatas": {
    "name": "Fréquence Maintenance",
    "webappThemeColor": "#00a8d9",
    "lang": "fr-FR",
    "charset": "utf-8",
    "colorPrimary": "#00a8d9",
    "colorAccent": "#ededed",
    "colorSecondary": "#ff8f00",
    "colorInfo": "#26a69a",
    "colorWarning": "#ffc107",
    "colorError": "#dd2c00",
    "colorSuccess": "#00e676",
    "iconFileName": "logo_webapp.jpg",
    "iconProperties": {},
    "supportEmailAddress": "digital@mediameeting.fr",
    "siteHeadTitle": "Fréquence Maintenance",
    "appName": "Fréquence Maintenance",
    "webappFonts": {
      "google": {
        "families": [
          "Roboto:300,400,500,700",
          "Merriweather:300,400,500,700",
          "Material+Icons"
        ],
        "downloadFonts": true
      }
    }
  }
}
  const metadatas = config.metadatas

  inject('metadatas', config.metadatas)
}
