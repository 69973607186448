const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next')
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge({}, config, {
  /**
   * common configuration here
   */
  metadatas: {
    name: `Fréquence Maintenance`,
    colorPrimary: '#00a8d9',
    iconFileName: 'logo_webapp.jpg'
  },

  analytics: {
    ga: 'UA-111736601-19',
    mixpanel: '0ae360f83fb1f8f1254d2a784ee945ec'
  },

  authMode: ['providers'],

  displayRGPDModal: true,

  pages: (pager) => {
    return pager
      .remove('radios')
      .remove('podcasts')
  }

},
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
